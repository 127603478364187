export function maps() {
    return new Promise((resolve) => {
        const mapElements = document.querySelectorAll('[data-custom-map]'),
            loadMaps = async () => {
                let { initMaps } = await import(/* webpackMode: "lazy" */ './map.async.js');

                initMaps(mapElements);
                resolve();
            };

        if (mapElements.length) {
            // load google maps if either Borlabs is not active on the page or the user already consented in the past,
            // or once the user consents in the current session
            if (!('BorlabsCookie' in window) || window.BorlabsCookie?.Consents?.hasConsent('maps')) {
                loadMaps();
            } else {
                let observer = new MutationObserver(() => {
                    // The only indicator, whether the user consented, is the existence of the .map__iframe element,
                    // as Borlabs removes it initially
                    if (Array.prototype.some.call(mapElements, (el) => Boolean(el.querySelector('.map__iframe')))) {
                        loadMaps();

                        observer.disconnect();
                    }
                });

                mapElements.forEach((mapElement) => {
                    observer.observe(mapElement, {
                        childList: true,
                        subtree: true,
                    });
                });
            }
        }
    });
}
